@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";

.personal-loan-wrapper {
  padding: 0 24px;
}
.personal-loan-wrapper__form {
  max-width: 428px;
  width: 100%;
  margin: 0px auto;
  .form-group-mobile,
  .monthly-income {
    position: relative;
  }
  .monthly-income p.inr {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    color: $theme-border-color;
  }
  .mob-number p {
    position: absolute;
    top: 18px;
    left: 24px;
    &::after {
      content: "";
      position: absolute;
      left: 18px;
      top: -3px;
      height: 24px;
      width: 1px;
      background-color: $theme-border-color;
      margin: 0px 10px;
    }
  }
}
button.loan-wrapper-apply-btn {
  width: 100%;
  padding: 12px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  cursor: pointer;
  @extend %transition;
  margin-bottom: 16px;
  &:hover {
    background-color: $white-color;
    color: #202124;
  }
  border-color: $theme-border-color;
  color: $white-color;
  background-color: $theme-primary-color;
}
.personal-loan-wrapper__form h2 {
  text-align: center;
  max-width: 280px;
  font-size: 1.375rem;
  width: 100%;
  margin: 0 auto 16px;
  color: #202124;
  font-weight: $normal-font-weight;
  line-height: 1.2;
}
.personal-loan-wrapper__form .form-group {
  margin-bottom: 16px;
}
.personal-loan-wrapper__form .form-group label {
  font-size: 0.875rem;
  color: #423f3f;
  line-height: 1.2;
  span.label-color {
    color: $theme-primary-color;
  }
}
.personal-loan-wrapper__form .form-group label {
  font-size: 0.875rem;
  margin-bottom: 4px;
  color: #423f3f;
  span {
    color: #dd3143;
  }
}
.form-group .personal-loan-wrapper__form-input.form-control {
  height: 56px;
  line-height: 56px;
  border: 1px solid $theme-border-color;
  padding: 0 24px;

  &.padding-mobile-number {
    padding-left: 58px;
  }
  &::placeholder {
    color: #9d9d9d;
    font-size: 1rem;
    font-weight: $light-font-weight;
  }
}
.form-group .personal-loan-wrapper__form-select {
  height: 56px;
  line-height: 56px;
  border: 1px solid $theme-border-color;
  padding: 0 24px;
}
.form-group .personal-loan-wrapper__form-select.form-control {
  color: #9d9d9d;
  font-size: 1rem;
  font-weight: $light-font-weight;
}
.date-of-birth {
  .personal-loan-wrapper__form-select.form-control {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.personal-loan-wrapper__form label.custom-radio-button__label {
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  @extend %transition;
  &:hover {
    border-color: #3369e8;
    color: #3369e8;
  }
}

.personal-loan-wrapper__form .custom-radio-button__input-type:checked ~ label.custom-radio-button__label {
  border-color: #3369e8;
  color: #3369e8;
}
.gap-16px {
  gap: 16px;
}
.add-more-your-family-members__border-bottom {
  padding: 16px 0;
  border-bottom: 1px solid $theme-border-color;
}
.add-more-your-family-members__checkbox {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.date-of-birth-wrapper {
  position: relative;
  .react-datepicker-wrapper {
    position: absolute;
    top: 50%;
    left: 16px;
    right: 0;
    transform: translateY(-50%);

    .react-datepicker__input-container input {
      border: none;
      color: #423f3f;
      font-weight: $light-font-weight;
      max-width: 400px;

      width: 100%;
      cursor: pointer;
    }
  }
}
