@import "../../../../..//assets/scss/common/_variables.scss";
.social-resposibility-wrapper {
  &--cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @media (max-width: $mobile-devices-max-width+px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

// .social-resposibility-wrapper__support-donate {
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-gap: 20px;
//   @media (max-width: $mobile-devices-max-width+px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
// }
