// @import "@/assets/scss/common/_variables.scss";
// @import "@/assets/scss/common/_extends.scss";
// @import "@/assets/scss/common/_mixins.scss";

.redcliff-wrapper {
  &.redcliff-logo {
    width: 100%;
    height: 100%;
  }

  h4 {
    color: #0056b3;
  }
}

.frequiently-booked-wrapper {
  display: flex;
  overflow-x: auto;
  padding: 20px;

  @media screen and (min-width: 1200px) {
    justify-content: center;
  }
}

.billed-contianer {
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  width: 200px;
  flex-shrink: 0;

  a {
    padding-left: 6px;
  }

  .icon-wrapper {
    width: 50px;
    height: 50px;
    background: rgb(228, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
}

// .health-wrapper{
//     display: grid;

// }
.package-contianer {
  display: grid;
  border: 2px solid #dedede;
  border-radius: 12px;
}

.package-top-section {
  .plan-name {
    p {
      color: #e4245d;
      font-size: 18px;
      font-weight: bold;
      padding: 15px 0 0 15px;
    }
  }

  .details-section {
    padding: 15px 15px 0 0;
    // text-align: end;

    p {
      font-size: 13px;
      color: #a1a1a1;
      font-weight: bold;
      margin-bottom: 12px;
    }

    span {
      color: #212121;
      padding: 4px;
      border-radius: 6px;
    }
    .report {
      background: #e4ffff;
    }

    .parameter {
      background: #eeeeee;
    }
  }
}

.package-middle-section {
  p {
    padding: 15px;
    font-size: 14px;
    color: #4c4c4c;
  }
}

.package-bottom-section {
  background: #fcf5f4;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 10px;
  border-radius: 0 0 12px 12px;
  align-items: center;

  .price-section {
    align-items: center;
    justify-content: center;
    p {
      font-size: 18px;
      font-weight: bold;
    }

    span {
      font-size: 14px;
      color: #a1a1a1;
      text-decoration: line-through;
    }
  }

  .discount-section {
    border-left: 1px solid #a1a1a1;
    padding-left: 10px;
    span {
      font-weight: bold;
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  .book-button {
    outline: none;
    border: none;
    padding: 12px 25px;
    background: #e00646;
    color: #fff;
    border-radius: 12px;
  }
}

.family-wrapper {
  display: grid;
  border-radius: 12px;
  background: rgb(224, 243, 221);
  background: linear-gradient(
    77deg,
    rgba(224, 243, 221, 1) 0%,
    rgba(242, 255, 240, 1) 100%
  );
  position: relative;

  .image-holder {
    position: absolute;
    bottom: 0;
    right: -15px;
    height: 200px;
    width: 200px;
  }

  .package-name {
    width: 70%;
    padding: 15px;
    h5 {
      line-height: 1.5rem;
    }
  }

  .family-middle-section {
    margin: 10px 0;
    width: fit-content;

    .sub-section {
      background: #fff;
      padding: 5px 15px;
      border-radius: 0 8px 0 0;
    }

    .family-parameter {
      background: #f2f4f5;
      padding: 5px 15px;
      margin: 0;
      p {
        font-size: 14px;
        color: #a1a1a1;
      }
      span {
        font-weight: bold;
        color: #8a8a8a;
      }
    }

    .family-discount-section {
      p {
        color: #7da1bc;
        font-weight: bold;
      }
    }
  }

  .family-price-section {
    p {
      font-size: 18px;
      font-weight: bold;
    }

    span {
      font-size: 14px;
      color: #a1a1a1;
      text-decoration: line-through;
    }
  }

  .recommended-section {
    background: #f2f4f5;
    width: fit-content;
    padding: 5px 15px;
    font-size: 14px;
    font-style: italic;
    border-radius: 0 0 0 12px;
    z-index: 9;
    border-radius: 0 8px 0 0;
    span {
      font-weight: bold;
    }
  }
}

.whychoose-wrapper {
  background: rgb(214, 234, 237);
  background: linear-gradient(
    77deg,
    rgba(214, 234, 237, 1) 0%,
    rgba(242, 253, 255, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whychoose-contianer {
  display: flex;
  align-items: center;
  .icon-container {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: 15px;
  }
  a {
    padding-left: 5px;
  }
}
