@import "../../../../assets/scss/common/_variables.scss";
@import "../../../../assets/scss/common/_extends.scss";
@import "../../../../assets/scss/pages/home-page-v2/contact-form.scss";
@import "./booknow.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
.home-pagev2-wrapper__title {
  font-size: 2.25rem;
  font-weight: $normal-font-weight;
  margin: 43px 0 32px;
  line-height: 2.875rem;
  @media (max-width: $mobile-devices-max-width+px) {
    margin: 24px 0 8px;
    font-size: 1.3rem;
    font-weight: $bold-font-weight;
  }
}
.home-pagev2-wrapper__title--margin {
  margin: 54px 0 32px;
  @media (max-width: $mobile-devices-max-width+px) {
    margin: 24px 0 0px;
    line-height: 1;
  }
}

.social-resposibility-wrapper__margin-bottom {
  margin-bottom: 32px;
}
.home-pagev2-wrapper__grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.youtube-wrapper {
  //border-radius: 8px;
  @media (max-width: $mobile-devices-max-width+px) {
    border: 1px solid #3369e8;
    //margin: 0 16px;
  }
}
.home-pagev2-wrapper__service-name {
  font-size: 1rem;
  font-weight: $normal-font-weight;
  margin-top: 8px;
  line-height: 1.5625rem;

  @media (max-width: $mobile-devices-max-width+px) {
    font-size: 0.875rem;
    line-height: 1.2;
    width: 100%;
  }
}
.home-pagev2-wrapper__service-name--height {
  max-width: 120px;
  width: 100%;
  margin: 8px auto 0;
}
.home-pagev2-wrapper__link-service-image {
  border: 1px solid $theme-border-color;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  display: flex;
  @media (max-width: $iphone-devices-max-width+px) {
    height: 120px;
    width: 120px;
  }
  @media (max-width: $mobile-devices-max-width+px) {
    margin: 0 auto;
  }
}
.home-pagev2-wrapper__link-service-image--margin {
  margin: auto;
  &.img-fluid {
    // max-width: 80px;
    @media (max-width: $mobile-devices-max-width+px) {
      // max-width: 60px;
    }
  }
}
.contact-banner-wrapper-new {
  margin-top: 54px;
}
.online-consultancy {
  background-color: #fcfdff;
  padding-bottom: 54px;
}

.btn-consulatncy {
  margin-top: 24px;
}
// .youtube-wrapper {
//   padding: 24.5px 44px;
//   background: transparent linear-gradient(266deg, #fff2f3 0%, #fff9e6 100%) 0% 0% no-repeat padding-box;
//   margin-bottom: 40px;
//   @media (max-width: $mobile-devices-max-width+px) {
//     padding: 24.5px 24px;
//   }
// }
// .youtube-wrapper__image {
//   margin-right: 2rem;
// }
// .youtube-wrapper__title {
//   font-size: 20px;
//   font-weight: $normal-font-weight;
//   color: #000000;
//   line-height: 1.25;
//   @media (max-width: $mobile-devices-max-width+px) {
//     font-size: 16px;
//   }
// }
// .youtube-wrapper__paragraph {
//   color: $theme-font-primary-color;
//   font-weight: $light-font-weight;
//   font-size: 0.75rem;
// }
// .youtube-play-btn {
//   color: $white-color;
//   background-color: #3369e8;
//   max-width: 224px;
//   width: 100%;
//   padding: 12px 0;
//   font-weight: $light-font-weight;
//   border: 1px solid transparent;
//   @extend %transition;
//   &:hover {
//     border: 1px solid #3369e8;
//     background-color: transparent;
//     color: #3369e8;
//   }
//   @media (max-width: $mobile-devices-max-width+px) {
//     max-width: 100%;
//     margin: 24px 0 0px;
//   }
// }
.order-medicine-wrapper__highlight3 {
  &:not(:last-child) {
    margin-right: 24px;
  }
}
.theme-primary-btn-homev2 {
  color: $white-color;
  background-color: #3369e8;
  max-width: 224px;
  width: 100%;
  padding: 12px 0;
  font-weight: $light-font-weight;
  font-size: 1rem;
  @media (max-width: $mobile-devices-max-width+px) {
    max-width: 100%;
  }
}

.order-medicine-wrapper__view {
  grid-gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  &.four-in-one-row {
    @media (min-width: $tablet-devices-min+px) {
      grid-template-columns: repeat(4, 1fr);
    }
    .order-medicine {
      h3.order-medicine-wrapper__packages-name {
        margin-top: 8px;
        font-size: 1.25rem;
        color: #000000;
      }
    }
  }
  @media (max-width: $mobile-devices-max-width+px) {
    max-width: 100%;
    padding: 0 16px;
    grid-gap: 16px;
  }
}
.order-medicine-wrapper__category-image {
  // max-width: 256px;
  width: 100%;
  cursor: pointer;
}
.order-medicine-wrapper__category-flex-view {
  display: flex;
  gap: 24px;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}
.order-medicine-wrapper__category-grid-view {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
}

.swiper-container-pagination {
  > .swiper-pagination {
    position: relative;
    &.swiper-pagination-bullets {
      bottom: 0;
      margin-top: 0.875rem;
      .swiper-pagination-bullet:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }
}

//order-medicines
.order-medicine-wrapper__packages-name {
  font-size: 1.5rem;
  color: $theme-font-heading-color;
  font-weight: $normal-font-weight;
  margin: 16px auto 0;
  text-align: center;
  line-height: 1;
}
.order-medicine-wrapper__header {
  padding: 13px 15px;
  border-bottom: 1px solid $theme-border-color;
}
.order-medicine-wrapper__header-title {
  font-size: 1.375rem;
  color: $theme-font-primary-color;
  font-weight: $normal-font-weight;
  @media (max-width: $mobile-devices-max-width+px) {
    font-size: 1.125rem;
  }
}
.order-medicine-wrapper__category-title {
  color: black;
  font-size: 1.25rem;
  // background: transparent url("images/offers/rectangle-gradient.svg") 0% 0% no-repeat padding-box;
  padding: 16px 0;
  font-weight: $medium-font-weight;

  @media (max-width: $mobile-devices-max-width+px) {
    margin: 32px 0 24px;
  }
}
.order-medicine-wrapper__category-title.order-medicine-wrapper__service-provider-title {
  margin-top: 0;
}
.order-medicine-wrapper__slider-margin {
  margin-bottom: 78px;
}
.order-medicine-wrapper__title {
  font-size: 2.25rem;
  font-weight: $normal-font-weight;
  margin: 54px 0 32px;
  line-height: 2.875rem;
  @media (max-width: $mobile-devices-max-width+px) {
    margin: 32px 0 24px;
  }
}
.order-medicine-wrapper__category-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px 15px;
  @media (max-width: $mobile-devices-max-width+px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.order-medicine-wrapper__link {
  border: 1px solid $theme-border-color;
  height: 200px;
  width: 200px;
  display: flex;
  border-radius: $base-border-radius;

  &:hover {
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.29);
  }
  @media (max-width: $mobile-devices-max-width+px) {
    min-height: 120px;
    height: 100%;
    min-width: 120px;
    width: 100%;
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    min-height: 190px;
    height: 100%;
    min-width: 120px;
    width: 100%;
  }
}
.order-medicine-wrapper__category-view {
  display: grid;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    // grid-gap: 16px;
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    justify-content: flex-start;
    grid-gap: 50px;
  }
}
.subcategory-wrapper__link {
  // border: 1px solid $theme-border-color;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: $base-border-radius;
  // gap: 24px;
  &:hover {
    // box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.29);
  }
  @media (max-width: $mobile-devices-max-width+px) {
    height: 8rem;
    width: 13rem;
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    height: 8rem;
    width: 13rem;
  }
}
.subcategory-wrapper__link-margin {
  margin: auto;
  // padding: 0 20px;
  @media (max-width: $mobile-devices-max-width+px) {
    // padding: 0 10px;
  }
}

.order-medicine-wrapper__link-margin {
  margin: auto;
  padding: 0 20px;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 0 10px;
  }
}
.order-medicine-wrapper__category-image-highlight {
  width: 100%;
  object-fit: contain;
  max-width: 535px;
  border-radius: 10px;
}
.order-medicine-wrapper__category-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.order-medicine-wrapper__category--bg-color {
  background-color: #fcfdff;
}
.order-medicine-wrapper__highlight-title {
  //max-width: 99px;
  width: 100%;
  margin: 0.5rem auto 0;
  font-size: 1.25rem;
  font-weight: $normal-font-weight;
  color: black;
  @media (max-width: $mobile-devices-max-width+px) {
    font-size: 0.75rem;
    max-width: 5rem;
  }
}
.htcircle-image-width {
  max-width: 840px;
  width: 100%;
  @media (max-width: $mobile-devices-max-width+px) {
    max-width: 100%;
  }
}
.order-medicine-wrapper__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;

  @media (max-width: ($iphone-devices-max-width - 0.02)+px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px;
  }
  @media (min-width: $iphone-devices-max-width+px) and (max-width: $mobile-devices-max-width+px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }
}
.order-medicine-wrapper__store-details {
  border: 1px solid #e8f0fe;
}
.order-medicine-wrapper__border-store {
  padding: 16px;
  border-radius: 0 0 8px 8px;
  // border-left: 1px solid #e8f0fe;
  // border-right: 1px solid #e8f0fe;
}
.img-fluid.order-medicine-wrapper__store-details-image {
  height: 140px;
  object-fit: contain;
  border-bottom: 1px solid #e8f0fe;
  padding: 8px;
}

.order-medicine-wrapper__store-details-title {
  font-size: 1rem;
  font-weight: $medium-font-weight;
  margin-bottom: 8px;
  line-height: 1.3;
  @media (max-width: $mobile-devices-max-width+px) {
    font-size: 1rem;
    font-weight: $normal-font-weight;
    line-height: 1.2;
  }
}
.order-medicine-wrapper__store-details-text {
  margin-bottom: 2.625rem;
  font-size: 0.875rem;
  line-height: 1.3;
  color: $theme-font-primary-color;
  @media (max-width: $mobile-devices-max-width+px) {
    padding-bottom: 24px;
  }
}
.order-medicine-wrapper__store-details-text--font {
  position: absolute;
  bottom: 24px;
  right: 0;
  left: 16px;
  span {
    color: #a09e9e;
    margin-right: 9px;
    text-decoration: line-through;
    @media (max-width: $mobile-devices-max-width+px) {
      display: block;
    }
  }

  color: #1539b0;
  font-size: 1rem;
  font-weight: $normal-font-weight;
  @media (max-width: $mobile-devices-max-width+px) {
    width: 100%;
  }
}
.order-medicine-wrapper__highlight3-image {
  border-radius: 10px;

  @media (max-width: $mobile-devices-max-width+px) {
    height: 6rem;
    width: 6rem;
  }
}

.order-medicine-wrapper__how-image {
  // border-radius: 10px;

  @media (max-width: $mobile-devices-max-width+px) {
    height: 6rem;
    width: 6rem;
  }
}

.medicine-package-wrapper {
  margin-bottom: 40px;
}
.medicine-package-wrapper__package-details {
  position: relative;
  cursor: pointer;
  //padding: 2px;
  @extend %transition;
  border: 1px solid $theme-border-color;
  border-radius: 10px;
  &:hover {
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.29);
    transform: translateY(2px);
    border-color: transparent;
    border-radius: 10px;
  }
}
.medicine-package-wrapper__position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.medicine-package-wrapper__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }
}

.book-lab-text-wrapper {
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 0 12px;
  }
}
.book-lab-text-wrapper__title {
  font-size: 2.25rem;
  font-weight: $normal-font-weight;
  @media (max-width: $mobile-devices-max-width+px) {
    font-size: 1.125rem;
    font-weight: $medium-font-weight;
  }
}
.book-lab-text-wrapper__sub-title {
  display: flex;
  align-items: center;
}
.book-lab-test-wrapper__slider-margin {
  margin-bottom: 1.5rem;
}
.dot {
  height: 2px;
  width: 2px;
  background-color: $theme-font-primary-color;
}
.book-lab-text-wrapper__list {
  font-size: 1rem;
  font-weight: $light-font-weight;

  &:not(:last-child) {
    margin-right: 8px;
  }
}
.book-lab-text-wrapper__list-color {
  color: #dd3143;
}
.book-lab-text-wrapper__details-title {
  font-size: 1rem;
  font-weight: $normal-font-weight;
}
.book-lab-text-wrapper__sub-title--color {
  span {
    color: #636060;
    margin-bottom: 8px;
    font-weight: $light-font-weight;
    font-size: 1rem;
  }
}
.book-lab-text-wrapper__list {
  p {
    font-weight: $medium-font-weight;
    font-size: 0.875rem;
  }
  ul {
    position: relative;
    li {
      padding-left: 10px;
      @media (max-width: $mobile-devices-max-width+px) {
        margin-bottom: 4px;
        font-size: 0.875rem;
      }
    }
    li:before {
      content: "";
      position: absolute;
      height: 3px;
      width: 3px;
      background-color: $theme-font-primary-color;
      border-radius: 50%;
      left: 0;
      margin-top: 8px;
      @media (max-width: $mobile-devices-max-width+px) {
        height: 2px;
        width: 2px;
        margin-top: 6px;
      }
    }
  }
}
.product-reward-wrapper__package-container.book-lab-test-wrapper__mobile {
  @media (max-width: $mobile-devices-max-width+px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.product-reward-wrapper__package-image.book-lab-test-wrapper__package-image-mobile {
  @media (max-width: ($iphone-devices-max-width - 0.02) + px) {
    width: 129px;
    min-height: 73px;
    border: 1px solid $theme-border-color;
    padding: 8px;
    border-radius: $base-border-radius * 2;
  }
  @media (min-width: $iphone-devices-max-width+px) and (max-width: ($small-mobile-devices-width - 0.02)+px) {
    width: 180px;
    min-height: 100px;
    border: 1px solid $theme-border-color;
    padding: 8px;
    border-radius: $base-border-radius * 2;
  }
  @media (min-width: $small-mobile-devices-width+px) and (max-width: $mobile-devices-max-width+px) {
    width: 310px;
    min-height: 150px;
    border: 1px solid $theme-border-color;
    padding: 8px;
    border-radius: $base-border-radius * 2;
  }
}
.product-reward-wrapper__button-package.book-lab-test-wrapper__button-package-mobile {
  @media (max-width: $mobile-devices-max-width+px) {
    display: none;
  }
}
.book-lab-test-wrapper__button-package {
  display: none;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 11px 16px;
    display: block;
    margin-top: 0;
  }
  @media (min-width: $small-mobile-devices-width+px) and (max-width: $mobile-devices-max-width+px) {
    padding: 11px 20px;
  }
}
.product-reward-wrapper__details.book-lab-test-wrapper__details {
  text-align: left;
}

.home-pagev2-wrapper__featured-services-list-item:hover
  .home-pagev2-wrapper__link-service-image {
  transform: translateY(-3px);
}
.home-banner-quiz {
  width: 100%;
  // height: 180px;
  border-radius: 5px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
}

//weightloss

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 50%;
}
.weight-loss-wrapper {
  font-family: "Rebyuu-font", sans-serif;

  .weight-loss-container {
    // background: #fbf9f9;
    position: relative;
    padding: 4rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;

    .wl-heading {
      display: flex;
      flex-direction: column;
      font-size: 72px;
      font-weight: 500;
      color: #707070;
      justify-content: center;
      font-weight: 500;
      width: 37%;
      z-index: 1;
      font-family: "Rebyuu-font", sans-serif;

      @media (max-width: $mobile-devices-max-width+px) {
        font-size: 44px;
        margin-top: 2rem;
        text-align: center;
        width: 100%;
      }
      @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
        font-size: 5rem;
        margin-top: 2rem;
      }
    }
  }
}

.custom-carousal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  // @media (max-width: $mobile-devices-max-width+px) {
  //   flex-wrap: wrap;
  // }

  .slider {
    // width: 80vw;
    height: 70vh;
    max-height: 577px;
    display: flex;
    gap: 12px;

    @media (max-width: $mobile-devices-max-width+px) {
      height: 350px;
      gap: 8px;
      width: 100%;
    }

    .slide {
      position: relative;
      flex: 1 0 auto;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      border-radius: 16px;

      @media (max-width: $mobile-devices-max-width+px) {
        border-radius: 12px;
      }

      .carousal-image {
        position: absolute;
        max-height: 100%;
        min-width: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 16px;

        @media (max-width: $mobile-devices-max-width+px) {
          border-radius: 12px;
        }
      }

      .image-position {
        left: 108%;
      }
    }

    .active {
      flex: 3;

      img {
        object-fit: cover;
      }
    }
  }
}

.bk-consultation-button {
  background: #3369e8;
  border-radius: 8px;
  padding: 16px 32px;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 3rem;
  font-weight: 600;

  .text_one {
    font-size: 16px;
    font-weight: 300;

    @media (max-width: $mobile-devices-max-width+px) {
      font-size: 12px;
    }
  }
  .text_two {
    font-size: 24px;
    font-weight: 500;

    @media (max-width: $mobile-devices-max-width+px) {
      font-size: 18px;
    }
  }

  @media (max-width: $mobile-devices-max-width+px) {
    width: 100%;
  }
}

.bk-consultation {
  background: #3369e8;
  border-radius: 8px;
  padding: 16px 32px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-top: 1.5rem;
  border: none;
  transition: all 0.3s ease-in-out;

  @media (max-width: $mobile-devices-max-width+px) {
    width: 100%;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.programs-heading {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  font-family: "Rebyuu-font", sans-serif;
}
.bold-weight {
  font-weight: 600;
  color: #423f3f;
}

.program-description {
  color: #707070;
  font-size: 24px;
  margin-bottom: 37px;
}

.review-customers {
  width: 170px;
  height: 56px;
}

.customers-count {
  font-size: 32px;
  font-weight: 700;
  color: #000;
}

.customer-reviews {
  border-left: 1px solid #dadce0;

  @media (max-width: $mobile-devices-max-width+px) {
    border: none;
  }
}

.program-buttons {
  font-size: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #dadce0;
  padding: 15px 24px;
  border-radius: 28px;
  gap: 6px;
  justify-content: center;
  width: fit-content;
  color: #423f3f;
  transition: all 0.3s ease-in-out;
}

.redirect-buttons {
  border: 1px solid #3369e8 !important;
  .link-arrow-forward {
    display: none;
  }
  &:hover {
    background: #3369e8;
    color: #fff;

    .link-arrow-forward-gray {
      display: none;
    }

    .link-arrow-forward {
      display: block;
    }
  }
}

.program-details {
  display: flex;
  justify-content: center;
  border-radius: 16px;
  align-items: center;

  @media (max-width: $mobile-devices-max-width+px) {
    flex-wrap: wrap;
  }

  .program-image {
    width: 100%;
    height: 558px;
    object-fit: cover;
    border-radius: 8px;

    @media (max-width: $mobile-devices-max-width+px) {
      width: 100%;
      height: 100%;
    }

    @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
      width: 100%;
      height: 100%;
    }
  }
}

.program-title {
  font-size: 48px;
  color: #000;
  font-weight: 700;
  line-height: 1.2;
}

.mobile-app-wrapper {
  background: #fbf9f9;
  padding: 3rem 0;

  .mobile-app-container {
    display: FLEX;
    // display: grid;
    gap: 2rem;
    flex-wrap: wrap;

    @media (min-width: $tablet-devices-min+px) {
      flex-wrap: nowrap;
    }

    .app-image-container {
      display: block;
      width: 100%;
      height: -webkit-fit-content;
      height: fit-content;

      @media (max-width: $mobile-devices-max-width+px) {
        width: 100%;
      }

      .mobile-app-image {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .app-features {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 20px 0;
    border-bottom: 1px solid #dadce0;
    padding-bottom: 20px;

    &:last-child {
      border-bottom: none;
    }

    .feature-list {
      color: #000000;
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.journey-container {
  display: flex;
  gap: 16px;
  align-items: self-start;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $mobile-devices-max-width+px) {
    flex-direction: column;
    align-items: center;

    .double-arrow-down {
      rotate: 90deg;
      margin: 0 !important;
    }
  }

  .dashed-border {
    border: 1px dashed #75747c;
    width: 20px;
  }

  @media (min-width: $large-devices-min+px) {
    flex-wrap: nowrap;
  }

  .journey-list-heading {
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
}

.testimonial-container {
  display: flex;
  background: #fff8f4;
  border: 1px solid #e5dfdb;
  border-radius: 16px;
  gap: 4rem;
  padding: 2rem;
  align-items: center;

  @media (max-width: $mobile-devices-max-width+px) {
    flex-wrap: wrap;
  }

  .orange-background {
    background: #ee6b4f;
  }

  .testimonial-images {
    width: 300px;
    border-radius: 16px;

    @media (max-width: $mobile-devices-max-width+px) {
      width: 100%;
    }

    // @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    //   width: 340px;
    // }
  }

  @media (min-width: $medium-devices-min+px) {
    .quote-bottom {
      position: absolute;
      bottom: 1rem;
      right: 0;
    }
  }

  .testimonial-description {
    color: #707070;
  }
}

.footer-border {
  border-bottom: 2px solid #e5dfdb;
}

.talk-expert-container {
  background: #fbf9f9;
}

.custom-checkbox {
  input[type="checkbox"] {
    position: relative;
    width: 18px;
    height: 18px;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 2px;
      left: 6px;
      width: 5px;
      height: 10px;
      border-style: solid;
      border-color: #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      color: #fff;
      border-color: #3369e8;
      background: #3369e8;
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  label {
    font-family: "Rebyuu-font", sans-serif;
    font-weight: 400;
  }
}

.blue_bold_text {
  color: #3369e8;
  font-weight: 500;
}

.diabetics-wrapper {
  width: 100%;

  .diabetics-container {
    display: grid;
    gap: 10px;

    @media (min-width: $large-devices-min+px) {
      grid-template-columns: repeat(2, 1fr);
      width: 75%;
    }

    .diabetic-cards {
      border: 1px solid #dadce0;
      display: flex;
      justify-content: start;
      align-items: center;
      border-radius: 8px;
      font-size: 18px;
    }
  }
}

.bullet-points {
  li {
    list-style: disc;
    margin: 4px 0;
  }
}

.text__20 {
  font-size: 24px;
  line-height: 1.4;
}

.banner-image {
  width: 100%;
  height: 100%;
  // max-height: 700px;
  object-fit: cover;
}
