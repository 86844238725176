@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';

.inner-page-banner-section-wrapper {
    background-color: $theme-primary-color;
    &.section-padding {
        padding: 45px 0;
    }
    h1 {
        margin-bottom: 16px;
    }
    h3 {
        max-width: 411px;
    }
}