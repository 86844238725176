@import '@assets/scss/common/_variables.scss';

.blog-wrapper {
  display: grid;
  column-gap: 1rem;
  row-gap: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: $tablet-devices-min+px){
    grid-template-columns: repeat(2, 1fr);
  }

  .main-blog-cards{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1rem;
    border-radius: 10px;
  }

  .blog-cover-image {
    width: 100%;
    height: 300px;
    background: #fff;
    border-radius: 8px;
    object-fit: cover;

    @media (min-width: $tablet-devices-min+px){
      height: 200px;
    }
  }
}

.recent-blog-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .blog-cover-image {
    width: 100%;
    height: 100px;
    background: #fff;
    border-radius: 8px;
    object-fit: cover;
  }

  .recent-container {
    min-width: 150px;
    max-width: 200px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    border-radius: 10px;

    .recent-blog-heading{
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.show-disc {
  list-style: disc;
}

.add-ol-style {
  list-style: auto;
}

.link-weight {
  font-weight: 600;
}

.add-background {
  background: #fff;
}

.blog-headings {
  font-size: 1.5rem !important;
}

.section-heading {
  font-size: 1rem;
}

.hba1c-table {
  width: 100%;
  max-width: 600px;
  border: 1px solid #d8d8d8;
  margin-top: 8px;

  th {
    border-bottom: 1px solid #d8d8d8;
    color: #000;
  }

  td,
  th {
    padding: 10px;
  }
}

.blog-images {
  width: 100%;
  margin: 10px 0;
  max-height: 440px;
  object-fit: contain;
}

.blog-redirect {
    color: #1539b0;
}
