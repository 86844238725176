@import "../../assets/scss/common/_variables.scss";
@import "../../assets/scss/common/_extends.scss";
@import "../../assets/scss/common/_mixins.scss";

%menu-underline {
  content: "";
  @extend %position-absolute;
  bottom: 0;
  height: 1px;
  left: 0;
}

.header-wrapper {
  background-color: $theme-body-background-color;
  border-bottom: 1px solid $theme-border-color;
  position: sticky;
  top: 0;
  z-index: 9999;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 10px 0;
  }
  .icon-logo {
    h6 {
      color: $theme-font-primary-color;
      font-size: (17 / $base-font-size) + rem;
      margin-bottom: 0 !important;
      @media (max-width: $mobile-devices-max-width+px) {
        font-size: (12 / $base-font-size) + rem;
      }
    }
  }
  @media (max-width: $mobile-devices-max-width+px) {
    .header-buttons {
      button {
        margin-right: 0 !important;
      }
    }
  }
  &--menu-wrapper {
    @media (max-width: $mobile-devices-max-width+px) {
      position: fixed;
      display: block !important;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      max-width: 240px;
      background-color: white;
      z-index: 111;
      padding: 16px;
      box-shadow: 2px 2px 10px #6b6868;
      transform: translateX(-120%);
      @include transition(600ms);
      max-height: 100vh;
      overflow-y: auto;
    }
    &.active {
      transform: translateX(0);
    }
    li {
      @extend %display-inline-block;
      &.d-none-tablet-above {
        @media (min-width: $tablet-devices-min+px) {
          display: none;
        }
      }
      padding: 0 12px;
      @media (max-width: $mobile-devices-max-width+px) {
        display: block;
        margin-bottom: 10px;
      }
      @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
        padding: 0 10px;
      }
      a {
        padding: 22px 0;
        color: #423f3f;
        @media (max-width: $mobile-devices-max-width+px) {
          padding: 10px 0;
        }
        @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
          padding: 18px 0;
        }
        @extend %display-inline-block;
        @extend %position-relative;
        &:after {
          @extend %menu-underline;
          background-color: $theme-primary-color;
          @extend %transition;
          width: 0;
        }
        &.active {
          &:after {
            width: 100%;
          }
        }
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  .logo-wrapper {
    &:hover {
      .logo-wrapper--meta {
        color: $theme-font-secondary-color;
      }
    }
  }
  .mobile-logo {
    .logo-wrapper {
      margin-bottom: 25px;
    }
  }
  .close-menu-icon-wrapper {
    @extend %position-absolute;
    top: 10px;
    right: 10px;
  }
  .theme-button {
    padding: 0px 24px;
    border: none;
    height: 40px;
    line-height: 40px;
  }
}

.backdrop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  @extend %transition;
  @extend %not-visible;
  &.active {
    @extend %visible;
  }
}

.app-downloads-dropdown {
  min-width: 413px;
  padding: 20px 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    min-width: 300px;
    max-width: 300px;
  }
}
.app-downloads-dropdown__header {
  .dropdown-item {
    display: inline-block;
    width: initial;
    padding: 4px;
  }
}
.app-downloads-dropdown__download-cta {
  a {
    display: inline-block;
  }
  img {
    max-width: 244px;
    @media (max-width: $mobile-devices-max-width+px) {
      max-width: 150px;
    }
  }
}

.app-downloads-dropdown-wrapper {
  .btn-secondary {
    background-color: transparent;
    padding: 0;
    border: none;
  }
}
