@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';

.why-partner-with-us-wrapper {
    &--grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 60px;
        @media (max-width: $mobile-devices-max-width+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 16px;
        }
    }
}
.why-partner-withus {
    @media (max-width: $mobile-devices-max-width+px) {
        max-width: 240px;
        margin: 0 auto;
    }
    h3 {
        max-width: 216px;
        margin: 0 auto;
        padding: 16px 0;
    }
}