@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';

.partner-with-us-banner-wrapper {
    background-size: cover;
    background-position: bottom;
    // background-color: rgba(0, 0, 0,0.6);
    // background-blend-mode: multiply;
    &--meta {
        max-width: 540px;
        @media (max-width: $tablet-devices-max+px) {
            max-width: 100%;
            margin-bottom: 24px;
        }
    }
    &--form-wrapper {
        max-width: 430px;
        width: 100%;
        padding: 32px;
        background-color: $white-color;
        border-radius: $base-border-radius;
        box-shadow: 0px 3px 6px rgba(0, 0, 0,0.29);
        @media (max-width: $tablet-devices-max+px) {
            max-width: 100%;
            padding: 24px;
        }
    }
}