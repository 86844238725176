.home_banner_container {
  background-color: #e8f0fd;
  min-height: 233px;
  border-radius: 5px;
  margin-top: 16px;
  overflow: hidden;
  cursor: pointer;
  .header {
    color: #202124;
    font-size: 36px;
  }
  .desc {
    color: #423f3f;
    font-size: 22px;
    font-weight: 300;
  }
  .location_desc {
    color: #ef5350;
    font-size: 16px;
  }
}
.kiosk_search_container {
  width: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: block; /* Adjusted width for smaller screens */
  }
  .item {
    margin-top: 16px;
    margin-bottom: 8px;
    margin-right: 10px;
    width: 30%;
    @media screen and (max-width: 768px) {
      width: 100%;
      display: block; /* Adjusted width for smaller screens */
    }
  }
}
.kiosk_main_container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  @media screen and (max-width: 768px) {
    width: 100%;
    display: block; /* Adjusted width for smaller screens */
  }
  .kiosk_container {
    box-sizing: border-box;
    border: 1px solid #dadce0;
    border-radius: 5px;
    min-height: 280px;
    overflow: hidden;
    margin-right: 2%;
    margin-bottom: 2%;
    .header {
      color: #000000;
      font-size: 22px;
      text-align: left;
    }
    .amount-linethrough {
      color: #dadce0;
      text-decoration: line-through;
      text-decoration-color: #dadce0;
      font-size: 16px;
    }
    .amount {
      color: #149925;
      font-size: 16px;
    }
    .desc {
      color: #5f6368;
      font-size: 16px;
    }
    .button-container {
      cursor: pointer;
      border: 1px solid #3369e8;
      width: 300px;
      height: 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #3369e8;
      }
    }
    .button-container-active {
      cursor: pointer;
      background-color: #3369e8;
      width: 300px;
      height: 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
      }
    }
  }
}
.kiosk_tests_sticky-header {
  transition: all 500ms ease;
  background-color: #e8f0fe;
  color: white;
  height: 54px;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
}
.header_text {
  font-size: 16px;
  color: #000;
  &__20 {
    font-size: 20px;
    color: #000;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    color: #423f3f;
  }
}
.time_options {
  display: flex;
  flex-wrap: wrap;
  .time_option_item {
    border: 1px solid #efefef;
    min-width: 200px;
    @media screen and (max-width: 768px) {
      min-width: 175px;
    }
    width: 10%;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    padding: 4px;
    color: #5f6368;
    cursor: pointer;
    // transition: all 500ms ease;
    text-align: center;
    &:hover {
      background-color: #e8f0fe;
    }
    &.active {
      background-color: #e8f0fe;
      border: 1px solid #3369e8;
      color: #3369e8;
      text-align: center;
    }
  }
  .time_option_item_disabled {
    border: 1px solid #f7f7f7;
    width: 10%;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    cursor: not-allowed;
    transition: all 500ms ease;
    text-align: center;
    color: #dadce0;
  }
  @media screen and (max-width: 768px) {
    .time_option_item,
    .time_option_item_disabled {
      width: 25%; /* Adjusted width for smaller screens */
    }
  }
}

/* Media query for smaller screens (adjust the max-width as needed) */

.user_modal_container {
  // padding: 0 18px;
  margin: 0 auto;
  width: 85% !important;
  @media screen and (max-width: 768px) {
    //  width: 100%;
    width: 85% !important;
  }
}

.review_container {
  h4 {
    color: #202124;
    font-size: 26px;
    font-weight: 500;
  }
  h6 {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    color: #5f6368;
  }
}

.color_primary {
  color: #3369e8 !important;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.succss_page_container {
  background-color: white;
  width: 90%;
  margin: 0 auto;
  min-height: 50vh;
  .succss_page_text {
    width: 50%;
    margin: 0 auto;
  }
}

//datepicker scss

.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid #efefef !important;
  border-top-left-radius: 0.3rem;
  padding: 10px 0;
  position: relative;
}
.react-datepicker__day-names {
  margin-bottom: -8px;
  padding: 10px !important;
  width: 300px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  /* color: #000; */
  font-weight: bold;
  font-size: 0.944rem;
  margin-top: 10px !important;
  display: none !important;
}
.react-datepicker__navigation--next {
  right: -3px;
  top: 20px !important;
}
.react-datepicker__navigation--previous {
  left: -3px;
  top: 20px !important;
}
.react-datepicker__day--selected {
  /* border-radius: 0.3rem; */
  border-radius: 0 !important;
  background-color: #3369e8 !important;
  color: #ffffff !important;
  border: 1px solid #3369e8 !important;
  font-weight: 800;
  border-radius: 50% !important;
  &:hover {
    color: #000 !important;
  }
}
.react-datepicker__day-name {
  color: rgb(81, 111, 144) !important;
  font-weight: 800;
  font-size: 14px;
  width: 2rem;
  line-height: 2rem;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
  margin: 0 auto;
  &:hover {
    border-radius: 0px !important;
    /* background-color: #408dfb !important; */
    border-radius: 50% !important;
    color: white;
    outline: 1px solid #408dfb !important;
    background-color: transparent !important;
    color: black;
  }
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  display: none !important;
}
.react-datepicker__navigation-icon::before {
  /* display: none !important; */
}
.react-datepicker__month-read-view {
  margin-top: 10px !important;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: white !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 45px !important;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.react-datepicker__year-option {
  height: 28px !important;
  &:hover {
    background-color: #f7faff !important;
  }
}
.react-datepicker__month-option {
  /* padding: 5px; */
  height: 28px !important;
  &:hover {
    background-color: #f7faff !important;
  }
}
/*  */

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid #efefef !important;
  width: 70px;
  padding: 5px;
  text-align: center;
  border-radius: 0.3rem;
  position: relative;
  visibility: visible !important;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  margin-top: 8px !important;
  // min-width: 80px;
  padding: 5px;
}

//Inline edit datepicker
.form_control-inline_datepicker {
  background-color: #f4f8ff;
  // border: 1px solid lightgray;
  border: 1px solid #efefef;
  padding: 8px;
  border-radius: 2px;
  padding-left: 15px;
  width: auto;
  font-size: 14px;
  // font-family: $font-family;
  color: #423f3f;
  &:focus {
    border-radius: 5px;
  }
}

.user-form-wrapper.modal-dialog {
  width: 200px !important;
  width: 100% !important;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}

.react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 2rem !important;
  line-height: 2rem !important;
  text-align: center;
  margin: 0.166rem !important;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc !important;
}

.modal-content {
  width: 97% !important;
}
.appointment-picker {
  border: 1px solid #dadce0;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
  font-size: 17px;
}

.nodata_text {
  margin: 0 auto;
  font-size: 18px;
  width: 50%;
  text-align: center;
}

.home-banner-quiz {
  width: 100%;
  height: 10%;
  img {
    height: 100%;
    width: 100%;
  }
}
.booknow_body {
  width: 80%;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.book_now_subtext {
  width: 120px;
  border: 1px solid #dadce0;
  font-size: 12px;
  padding: 3px;
  border-radius: 3px;
}
.booknow_border {
  border-bottom: 1px solid #e8f0fe;
}
.border-radius__10 {
  border-radius: 20px !important;
}
.forward_arrow {
  height: 20px;
  width: 45px !important;
}
.booknow_header {
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 20px;
    color: #636060;
    font-weight: 400;
  }
}
.booknow_subheader {
  @media screen and (max-width: 768px) {
    font-size: 20px;
    color: #636060;
  }
}
.data_container {
  border: 1px solid #e8f0fe;
  min-width: 200px;
  height: 44px;
  margin-right: 10px;
  margin-top: 8px;
  border-radius: 5px;
  padding-right: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    color: #636060;
  }
  .arrotext {
    font-size: 14px;
    color: #423f3f;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      color: #636060;
    }
  }
}
.data_container_2_main {
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  .data_container_2 {
    border: 1px solid #e8f0fe;
    width: 207px;
    border-radius: 8px;
    margin-right: 15px;
    @media screen and (max-width: 768px) {
      margin-bottom: 8px;
      width: 100%;
    }
    .icon {
      margin: 0 auto;
      height: 48px;
      width: 48px;
    }
    .header {
      color: #000000;
      font-size: 16px;
    }
    .desc {
      color: #636060;
      font-size: 14px;
    }
  }
}

.data_container_3 {
  width: 304px;
  margin-right: 8px;
  @media screen and (max-width: 768px) {
    margin-bottom: 8px;
    width: 100%;
  }
}
.desc_text {
  font-size: 14px;
  color: #707070;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    color: #707070;
    margin-top: 30px !important;
  }
}
.booknow_learn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: block !important; /* Adjusted width for smaller screens */
  }
  .desc {
    @media screen and (max-width: 768px) {
      font-size: 22px;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
.w-80 {
  width: 80%;
}
.main_button {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.icon-image {
  width: 304px;
  height: 184px;
}
.form-container {
  width: 50%;
  // height: 56px;
  padding: 18px 24px;
  border-radius: 5px;
  border: 1px solid #dadce0;
  margin-right: 5px;
  display: flex;
  align-items: center;
  min-height: 56px;
  &.active {
    border: 1px solid #3369e8;
    color: #3369e8;
  }
}
