@import '@assets/scss/common/_variables.scss';

.our-core-values-wrapper {
    &--values {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        @media (max-width: $mobile-devices-max-width+px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .values-card {
        border: 1px solid $theme-border-color;
        border-radius: 8px;
        padding: 32px;
        h3 {
            padding: 8px 0;
        }
    }
}