@import '../../../../assets/scss/common/_variables.scss';
.about-us-wrapper {
   &--image{
    height: 324px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    .about-us-header-container {
        left: 0;
        right: 0;
        bottom: 0;
    }
    h3 {
        @media (max-width:$mobile-devices-max-width+px){
            font-size: (38/$base-font-size)+rem;
        }
        // position: absolute;
        // bottom: 10px;
        font-size: (48/$base-font-size)+rem;
        color:$white-color;
    }
   }
    &--details{
        padding: 54px 0;
        @media (max-width:$mobile-devices-max-width+px){
            padding: 30px 20px;
        }
        h4 {
            @media (max-width:$mobile-devices-max-width+px){
                font-size: (26/$base-font-size)+rem;
            }
            font-size: (36/$base-font-size)+rem;
            line-height: 1.1;
            color: $theme-font-heading-color;
        }
        p{
            @media (max-width:$mobile-devices-max-width+px){
                font-size: (18/$base-font-size)+rem;
            }
            font-size: (22/$base-font-size)+rem;
            color: $theme-font-primary-color;
            
        }
    }
}
.about-letter-cover-wrapper{
    &--image{
        height: 638px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    //    @media (max-width:$mobile-devices-max-width+px){
    //     height: 500px;
    //     width: 100%;
    // }
    }
    .letter-cover-details{
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        @media (max-width:$mobile-devices-max-width+px){
            max-width: 300px;
            margin: auto 20px;
        }
        @media (min-width:$mobile-devices-max-width+px) and (max-width:$tablet-devices-max+px){
            max-width: 450px;
            margin: auto;
        }
        h3{
            color: $white-color;
            font-size: (48/$base-font-size)+rem;
            line-height: 1.2;
            padding-bottom: 10px;
        }
        p{
            padding-bottom: 42px;
            font-size: (22/$base-font-size)+rem;
            color: $white-color;
            max-width: 450px;
        }
    }
}
