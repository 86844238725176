@import "../../../../assets/scss/common/_variables.scss";
@import "../../../../assets/scss/common/_extends.scss";
.home-pagev2-wrapper__title-loading {
  height: 46px;
  max-width: 580px;
  width: 100%;
  margin: 43px auto 32px;
  @media (max-width: $mobile-devices-max-width + px) {
    max-width: 100%;
    height: 32px;
  }
}
.home-pagev2-wrapper__link-image-loading {
  height: 194px;
  max-width: 155px;
  width: 100%;
  @media (max-width: $mobile-devices-max-width + px) {
    height: 188px;
    max-width: 100%;
  }
}

.home-pagev2-wrapper__link-service-image-loading {
  height: 148px;
  width: 148px;
  border-radius: 50%;
  @media (max-width: $mobile-devices-max-width + px) {
    height: 120px;
    width: 120px;
  }
}
.home-pagev2-wrapper__slider-loading {
  max-width: 1048px;
  width: 100%;
  height: 495px;
  border-radius: 8px;
  margin: 54px auto 32px;
  @media (max-width: $mobile-devices-max-width + px) {
    max-width: 100%;
    height: 250px;
  }
}
.home-pagev2-wrapper__order-medicine-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}
.home-pagev2-wrapper__order-medicine-box-loading {
  max-width: 520px;
  width: 100%;
  height: 348px;
  border-radius: 8px;
  @media (max-width: $mobile-devices-max-width + px) {
    height: 188px;
    max-width: 100%;
  }
}
.home-pagev2-wrapper__grid--mobile {
  @media (max-width: $mobile-devices-max-width + px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
  }
}
.order-medicine-wrapper__header-title-loader {
  height: 28px;
  max-width: 150px;
  width: 100%;
  @media (max-width: $mobile-devices-max-width + px) {
    max-width: 100%;
  }
}
.service-provider-box {
  height: 190px;
  width: 190px;
  border-radius: 8px;
  @media (max-width: $mobile-devices-max-width + px) {
    width: 100px;
    height: 100px;
  }
}
