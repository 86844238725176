@import '../../../../assets/scss/common/_variables.scss';
@import '../../../../assets/scss/common/_extends.scss';
@import '../../../../assets/scss/common/_mixins.scss';
.video-wrapper {
  &__header {
    margin: 32px 0 40px;
    @media (max-width: $mobile-devices-max-width+px) {
      margin: 32px 0;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    @media (max-width: $mobile-devices-max-width+px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__footer {
    .blog-btn {
      padding: 10px 30px;
      background-color: $white-color;
      border: 1px solid $theme-primary-color;
      border-radius: $base-border-radius;
      color: $theme-primary-color;
      @extend %transition;
      &:hover {
        background-color: #1a73e840;
        border-color: $white-color;
      }
    }
  }
  &__title {
    font-size: (36 / $base-font-size) + rem;
    font-weight: $normal-font-weight;
    margin-bottom: 8px;
    @media (max-width: $mobile-devices-max-width+px) {
      font-size: (22 / $base-font-size) + rem;
    }
  }
  &__title-description {
    font-size: (22 / $base-font-size) + rem;
    max-width: 916px;
    margin: 0 auto;
    width: 100%;
    font-weight: $light-font-weight;
    @media (max-width: $mobile-devices-max-width+px) {
      font-size: (18 / $base-font-size) + rem;
      max-width: 100%;
    }
  }
}
.experience-wrapper {
  &__video-popup-wrapper {
    @extend %position-fixed;
    @extend %display-flex;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 111;
    @extend %not-visible;
    @include transition(1s);
    &.active {
      @extend %visible;
      .video-wrapper {
        transition-delay: 300ms;
        transform: scale(1);
      }
    }
    .video-wrapper {
      @extend %position-relative;
      background-color: $white-color;
      padding: 10px;
      box-shadow: 2px 2px 10px #000;
      border-radius: $base-border-radius;
      transform: scale(0.85);
      @extend %transition;
      @media (min-width: $tablet-devices-min+px) {
        min-width: 600px;
      }
      @media (max-width: $mobile-devices-max-width+px) {
        height: 280px;
        iframe {
          height: 260px;
        }
      }
      .close-button {
        @extend %position-absolute;
        width: 40px;
        height: 40px;
        background-color: $white-color;
        box-shadow: 2px 2px 10px #000;
        border-radius: 50%;
        @extend %text-center;
        line-height: 40px;
        right: 0;
        top: 0;
        transform: translate(15px, -15px);
      }
    }
  }
}
.video-card-wrapper {
  border: 1px solid $theme-border-color;
  border-radius: 8px;
  @extend %transition;
  &:hover {
    box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.29);
    transform: translateY(-3px);
  }
  &__details {
    padding: 24px;
  }
  &__type {
    font-size: (12 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    margin-bottom: 10px;
  }
  &__date {
    font-size: (12 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    margin-bottom: 10px;
  }
  &__title {
    font-size: (22 / $base-font-size) + rem;
    font-weight: $normal-font-weight;
    margin-bottom: 10px;
    line-height: 1.3;
    text-transform: capitalize;
  }
  &__description {
    font-size: (16 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    line-height: 1.3;
  }
  &__play-img-style {
    padding: 5px;
    background-color: $white-color;
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
  &__play-vdo {
    font-weight: $light-font-weight;
    font-size: 1rem;
    margin-left: 8px;
    color: $white-color;
    padding-right: 10px;
    font-size: (12 / $base-font-size) + rem;
  }
  &__image-play {
    position: relative;
    &--position {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      border-radius: 4px;
      padding: 10px 12px;
      background-color: rgba(0, 0, 0, 0.6);
      max-width: 110px;
      width: 100%;
      cursor: pointer;
      @extend %transition;
      &:hover {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }
}
.video-card-wrapper__video-image {
  border-radius: 8px 8px 0 0;
  height: 180px;
  object-fit: cover;
  width: 100%;
}

.offers-wrapper__body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  @media (max-width: $mobile-devices-max-width+px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.offers-wrapper__header {
  padding: 32px 0 24px;
  border-bottom: 1px solid $theme-border-color;
  margin-bottom: 24px;
}
.offer-card-wrapper {
  border: 1px solid $theme-border-color;
  border-radius: 8px;
  @extend %transition;
  &:hover {
    box-shadow: 2px 10px 12px rgba(0, 0, 0, 0.29);
  }
}
.offer-card-wrapper__image {
  // height: 64px;
  max-height: 64px;
  height: auto;
  width: 90px;
  margin-right: 25px;
}
.offer-card-wrapper__header {
  padding: 24px;
}
.offer-card-wrapper__header-title {
  color: $theme-logo-color;
  font-size: (22 / $base-font-size) + rem;
  line-height: 1.3;
  font-weight: $normal-font-weight;
}
.offer-card-wrapper__header-text {
  color: $theme-logo-color;
  font-size: (16 / $base-font-size) + rem;
  line-height: 1.3;
  font-weight: $light-font-weight;
  margin-top: 4px;
}
.offer-card-wrapper__footer {
  border-top: 1px dotted $theme-border-color;
  padding: 16px 24px;
}
.offer-card-wrapper__footer-link {
  color: $theme-primary-color;
  font-size: (16 / $base-font-size) + rem;
}
.offers-wrapper__cta-wrapper-btn {
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  padding: 18px 48px;
  color: $theme-primary-color;
  font-size: (16 / $base-font-size) + rem;
  background-color: $white-color;
  @extend %transition;
  &:hover {
    background-color: $theme-border-color;
  }
}
