@import "normalize.scss";
@import "extends.scss";

.theme-primary-color {
  color: $theme-primary-color;
}
.d-none-desktop {
  @media (min-width: $tablet-devices-min+px) {
    display: none;
  }
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.theme-button {
  padding: 10px 24px;
  border-radius: $base-border-radius;
  border: none;
  @extend %text-capitalize;
  @extend %display-inline-block;
  &.theme-primary-btn {
    background-color: $theme-primary-color;
    color: $white-color;
  }
  &.theme-success-btn {
    background-color: #149925;
    color: $white-color;
    min-width: 300px;
  }
  &.theme-secondary-btn {
    background-color: #e8f0fe;
    color: $theme-primary-color;
  }
  &.theme-white-btn {
    background-color: $white-color;
    color: $theme-primary-color;
    border: 1px solid $theme-border-color;
  }
  &.theme-bordered-btn {
    border: 1px solid $theme-border-color;
    padding: 7px 24px;
  }
  &:disabled {
    background-color: #bababac7;
  }
}

.custom-container {
  max-width: 1095px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 0 16px;
  }
}
.custom-form-container {
  max-width: 895px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 0 16px;
  }
}
.custom-container-bookings {
  min-height: 50vh;
}
.white-color {
  @extend %white-color;
}
.theme-secondary-text-color {
  @extend %theme-secondary-text-color;
}
.theme-secondary-color {
  color: $theme-secondary-color;
}
.normal-font {
  @extend %normal-font;
}
.light-font {
  @extend %light-font;
}
.bold-font {
  @extend %bold-font;
}
.medium-bold-font {
  @extend %medium-bold-font;
}
.flex-wrap {
  flex-wrap: wrap;
}
.arrow {
  &.swiper-button-disabled {
    opacity: 0.33;
    pointer-events: none;
  }
  img {
    height: 36px;
    width: 36px;
    border: 1px solid $theme-border-color;
    border-radius: 50%;
    padding: 8px;
    opacity: 1;
    &:hover {
      background-color: #fabc2c;
    }
    @media (max-width: $mobile-devices-max-width+px) {
      height: 30px;
      width: 30px;
    }
  }
}

.section-padding {
  padding: 20px 0;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 20px 0;
  }
}
.section-padding-v2 {
  padding: 54px 0;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 32px 0;
  }
}
@media (min-width: $tablet-devices-min+px) {
  .d-none-tablet-above {
    display: none;
  }
}
@media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max +px) {
  .flex-direction-column-tablet {
    flex-direction: column;
  }
}
@media (max-width: $mobile-devices-max-width+px) {
  .d-none-mobile {
    display: none;
  }
  .flex-direction-column-mobile {
    flex-direction: column;
  }
  .mobile-theme-button {
    height: 36px;
  }
}
@media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
  .flex-direction-column-tablet {
    flex-direction: column;
  }
}
.form-group {
  &.rebeyu-font {
    font-family: "Rebyuu-font", sans-serif !important;
  }
  label {
    margin-left: 5px;
    @extend %light-font;
    font-size: (14 / $base-font-size) + rem;
    font-weight: 500;
  }
  input {
    &.form-control {
      height: 44px;
    }
  }
  textarea {
    resize: none;
  }
  .form-control {
    @extend %placeholder;
  }
}
.input-border {
  border-radius: 38px;
}
.cursor-pointer {
  cursor: pointer;
}
.form-error {
  font-size: (12 / $base-font-size) + rem;
  margin-top: 5px;
  color: red;
  height: 15px;
}
.form-group {
  margin-bottom: 10px;
}
.small-font {
  font-size: (12 / $base-font-size) + rem;
}
.display-inline-block {
  display: inline-block;
}

.inner-page-section-block-spacing {
  margin: 40px 0;
}
.home-pagev2-wrapper__link-image {
  cursor: pointer;
  @extend %transition;
  &:hover {
    border-radius: 10px;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.29);
  }
}

.transition {
  @extend %transition;
}
