@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';

.testimonial-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: $tablet-devices-max+px) {
        grid-template-columns: repeat(1, 1fr);
    }
    &--image {
        background-size: cover;
        background-position: top;
        @media (max-width: $tablet-devices-max+px) {
            min-height: 400px;
        }
    }
    &--meta {
        padding: 55px 40px;
        color: $white-color;
        @media (max-width: $mobile-devices-max-width+px) {
            padding: 25px 20px;
        }
        .testimonial-text {
            max-width: 474px;
            @media (max-width: $tablet-devices-max+px) {
                max-width: 100%;
            }
        }
    }
}