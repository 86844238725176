@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";

.thyro-background {
  background-image: linear-gradient(180deg, #ffffff, #eff3f9);
}

.t-package-subheading {
  font-size: 16px;
  color: #556a8c;
  font-weight: 500;
  text-align: center;
  width: 75%;
}

.thick-bottom-border {
  width: 12rem;
  height: 2px;
  background: #dedede;
  margin: 1rem 0 2rem 0;
}

.t-filter-buttons {
  font-size: 14px;
  border: 1px solid #1a2e4d;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #fff;
}

.t-filter-buttons-active {
  color: #3369e8 !important;
  border: 1px solid #3369e8 !important;
}

.t-package-wrapper {
  display: grid;
  gap: 1.5rem;

  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .t-package-container {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 1.4rem 1.4rem 0 1.4rem;
    background: #fff;
    display: grid;
    align-content: space-between;

    .t-package-name {
      font-size: 18px;
    }

    .t-package-included-text {
      font-size: 14px;
      color: #707070;
      font-weight: 500;
    }

    .t-package-details {
      font-size: 14px;
      color: #707070;
      font-weight: 500;
      margin-left: 1rem;
      margin-bottom: 1.2rem;

      li {
        list-style: disc;
        margin: 8px 0;
      }
    }

    .t-package-footer {
      border-top: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      padding: 14px;
      align-items: center;

      .t-package-price {
        font-size: 18px;
        color: #000;
        font-weight: 600;
      }

      
    }
  }
}

.t-book-now {
  width: 114px;
  height: 40px;
  border: 1px solid #3369e8;
  color: #3369e8;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  outline: none;
  border-radius: 4px;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.react-tabs__tab {
  font-size: 14px;
  border: 1px solid #1a2e4d!important;
  height: 40px;
  width: 120px;
  display: flex!important;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
}

.react-tabs__tab--selected{
  color: #3369e8 !important;
  border: 1px solid #3369e8 !important;
  border-radius: 20px;
}

.react-tabs__tab-panel--selected{
  margin-top: 2rem;
}