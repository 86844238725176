@import '@assets/scss/common/_variables.scss';
.tesimonials-wrapper {
  background-color: #f8f9fd;
  padding: 55px 0;
  @media (max-width: $mobile-devices-max-width) {
    padding: 40px 0;
    .flex-direction-column-mobile {
      flex-direction: column;
    }
  }
  &--title {
    @media (max-width: $medium-devices-max+px) {
      width: 100%;
      margin-bottom: 16px;
      text-align: center;
    }
    h3 {
      color: $theme-primary-color;
      font-size: (22 / $base-font-size) + rem;
    }
    h2 {
      max-width: 300px;
      line-height: 1.1;
      margin: 8px 0 24px;
      font-size: (48 / $base-font-size) + rem;
      @media (max-width: $medium-devices-max+px) {
        margin: 8px auto 24px;
        max-width: 500px;
      }
    }
  }
  &--information {
    .information-container {
      @media (max-width: $mobile-devices-max-width+px) {
        .flex-direction-column-mobile {
          flex-direction: column;
        }
      }
    }

    .text {
      height: 148px;
      width: 318px;
      background-color: $white-color;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
      @media (max-width: $mobile-devices-max-width+px) {
        width: 100%;
      }
      p {
        font-size: (12 / $base-font-size) + rem;
        color: $theme-logo-color;
        padding-bottom: 10px;
      }
    }
  }
}
