@import '@assets/scss/common/_variables.scss';
@import '@assets/scss/common/_extends.scss';
.contact-us-wrapper {
    &--banner-wrapper {
        @extend %position-relative;
        background-color: $theme-primary-color;
        &.section-padding {
            padding: 45px 0;
        }
        &--banner-meta {
            max-width: 411px;
        }
        .partner-with-us-banner-wrapper {
         &--form-wrapper {
            @extend %position-absolute;
            right: 0;
            @media (max-width:$tablet-devices-max+px){
                position: relative;
                margin-top: 24px;
            }
         }   
        }
    }
    &--contact-section {
        padding-bottom: 120px;
        @media (max-width:$tablet-devices-max+px){
            padding-bottom: 20px;
        }
    }
}