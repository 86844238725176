@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";

.healthians-logo {
  width: 8rem;
}

.healthians-banner{
  height: 100px;
  object-fit: cover;

  @media  (min-width: $tablet-devices-min+px) {
    height: auto;
    object-fit: contain;
  }
}

.healthians-heading {
  text-align: center;
  color: #18a0a7;
  font-size: 28px;
  margin: 30px 0;
}

.healthians-package-wrapper {
  display: grid;
  gap: 1.5rem;

  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .h-package-container {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    display: grid;
    align-content: space-between;

    .h-package-heading {
      font-size: 18px;
      letter-spacing: 0.32px;
      color: #000000;
      padding: 1.2rem;
    }

    .h-package-includes {
      font-size: 14px;
      color: #423f3f;
      background: #f7f7f7;
      border-radius: 0 20px 20px 0;
      padding: 0.8rem 2rem 0.8rem 1.2rem;
    }

    .h-package-details {
      font-size: 14px;
      padding: 1.4rem 1.2rem 2rem 2rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      li {
        list-style: disc;
        margin: 8px 0;
        color: #423f3f;
      }
    }

    .h-package-discount-wrapper {
      background: #fdf9f1;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .h-package-dicount-text {
        font-size: 14px;
        color: #423f3f;
        font-weight: 500;
      }

      .h-package-upto-text {
        font-size: 12px;
        color: #707070;
      }

      .h-package-discount-section {
        border: 1px solid #eea139;
        padding: 4px 20px;
      }

      .h-package-price-section {
        border: 1px solid #eea139;
        padding: 3px 20px;
        background: #eea139;

        .h-package-offer-text {
          font-size: 12px;
          color: #423f3f;
        }
        .h-package-strike-through {
          text-decoration: line-through;
        }
      }
    }

    
  }
}

.h-package-book-now {
  color: #fff;
  background: #ee6b4f;
  border-radius: 30px;
  width: 100%;
  padding: 14px 10px;
  margin: 1rem;
  outline: none;
  border: none;
}