@import "../../../../assets/scss/common/_variables.scss";
@import "../../../../assets/scss/common/extends";
.shimmer-circle {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
}
.shimmer-button {
    width: 90px;
    height: 40px;
    display: inline-block;
    border-radius: $base-border-radius;
}
.animate-loader {
    animation: shimmer 3s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}
@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}
.loading-banner-wrapper {
    &--banner-heading {
        margin: 30px auto;
        height: 110px;
        border-radius: 2 * $base-border-radius;
        &.no-margin {
            margin: 10px auto;
        }
    }
    &--sub-banner-heading {
        margin: 30px auto;
        height: 50px;
        width: 50%;
        border-radius: 2 * $base-border-radius;
    }
    &--end-to-end {
        max-width: 95%;
        height: 200px;
        margin: 60px auto 0;
        border-radius: 2 * $base-border-radius;
    }
    .product-listing {
        .loading-banner-wrapper {
            &--banner-heading {
                height: 80px;
            }
        }
    }
}
