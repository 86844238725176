.lab-text-contact-form-wrapper__contact-form {
  max-width: 430px;
  margin: 0 auto;
  width: 100%;
}
.lab-text-contact-form-wrapper__contact-form-title {
  text-align: center;
  font-size: 1.375rem;
  font-weight: $medium-font-weight;
}
.theme-primary-button {
  background-color: #3369e8;
  border-radius: 8px;
  color: $white-color;
  border: 1px solid $white-color;
  @extend %transition;
  font-weight: $normal-font-weight;
  height: 44px;
  &:hover {
    color: $theme-primary-color;
    background-color: $white-color;
    border: 1px solid $theme-primary-color;
  }
}
.lab-text-contact-form-wrapper__text {
  max-width: 18.25rem;
  width: 100%;
  margin: 0 auto;
  span {
    color: $theme-primary-color;
  }
}
.lab-text-contact-form-wrapper__text--width {
  max-width: 504px;
}
.form-control.lab-text-contact-form-wrapper__select {
  height: 44px;
}

.form-control.lab-text-contact-form-wrapper__input {
  height: 44px;
  font-size: 1rem;
  padding-left: 74px;
  &::placeholder {
    font-size: 1rem;
  }
}
.form-control.lab-text-contact-form-wrapper__input--spacing {
  padding-left: 24px;
}
.form-group input.form-control {
  height: 56px !important;
  // padding-top: 10px;
}
.lab-text-contact-form-wrapper__contact-form-position {
  position: relative;
}
.lab-text-contact-form-wrapper__contact-form-position--absolute {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  border-right: 1px solid $theme-border-color;
  padding: 6px 4px 6px 0;
}

.lab-text-contact-form-wrapper__image {
  width: 335px;
  margin: 0 auto 24px;
}
.lab-text-contact-form-wrapper__image-container {
  display: flex;
}
.lab-text-contact-form-wrapper__theme-primary-button {
  background-color: $theme-primary-color;
  color: $white-color;
  font-weight: $normal-font-weight;
  font-size: 1rem;
  @extend %transition;
  border: 1px solid transparent;
  max-width: 180px;
  width: 100%;
  &:hover {
    color: $theme-primary-color;
    background-color: $white-color;
    border: 1px solid $theme-primary-color;
  }
}
.lab-text-contact-form-wrapper__theme-secondary-btn {
  border: 1px solid $theme-border-color !important;
  color: $theme-primary-color;
  background-color: $white-color;
  @extend %transition;
  max-width: 180px;
  width: 100%;
  &:hover {
    background-color: $theme-primary-color;
    color: $white-color;
    border: 1px solid $theme-primary-color;
  }
}
