@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";

.apollo-logo {
  width: 8rem;
}

.apollo-package-wrapper {
  display: grid;
  gap: 1.5rem;

  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .apollo-package-cards {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .a-package-heading {
      font-size: 18px;
      letter-spacing: 0.32px;
      color: #000000;
    }

    .a-package-includes {
      font-size: 14px;
      color: #707070;
      font-weight: 500;
    }

    .a-package-price-text {
      font-size: 16px;
      color: #423f3f;
      font-weight: 500;
    }

    .a-package-actual-price {
      font-size: 12px;
      color: #707070;
      text-decoration: line-through;
    }

    .a-package-discount-text {
      font-size: 16px;
      color: #149925;
      font-weight: 500;
    }

    
  }
}

.a-book-now {
  background: #186c88;
  color: #fff;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 0 1rem;
  border-radius: 8px;
  height: 40px;
}