@import "@assets/scss/common/_variables.scss";
@import "@assets/scss/common/_extends.scss";

.lead-form-content {
  width: 100% !important;
  padding: 2rem;
  margin: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  max-height: 100vh;
  overflow-y: auto;

  @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
    margin: auto !important;
    max-width: fit-content;
  }

  @media screen and (min-width: 993px) {
    margin: auto !important;
    max-width: fit-content;
  }

  .lead-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: #efefef;
    border-radius: 50%;
    padding: 10px;
    outline: none;
    border: none;
  }
}

.lead-form-wrapper {
  display: grid;
  width: 100%;

  .lead-label {
    color: #423f3f;
    margin-bottom: 4px;
  }

  .add-margin-bottom-20 {
    margin-bottom: 20px;
  }

  .lead-input,
  .lead-select {
    border: 1px solid #dadce0;
    padding: 14px 20px;
    width: 100%;
    max-width: 360px !important;

    &::placeholder {
      color: #dadce0;
    }

    @media (min-width: $tablet-devices-min+px) and (max-width: $medium-devices-min+px) {
      width: 360px !important;
    }

    @media screen and (min-width: 993px) {
      width: 360px !important;
    }
  }
}

.select-placeholder {
  color: #dadce0;
}

.lead-submit {
  background: #1a73e8;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 54px;
  width: 100%;
  border-radius: 54px;
  margin-top: 34px;
}
