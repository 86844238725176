@import '../../../assets/scss/common/_variables.scss';

.page-not-found-wrapper {
    // background-color: $theme-background-secondary-color;
    background-color: #fff;
    img {
        max-width: 600px;
    }
    @media (max-width: $mobile-devices-max-width+px) {
        text-align: center;
    }
}