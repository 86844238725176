@import '../../../../../assets/scss/common/_variables.scss';
.responsibility-card.responsibility-card--new {
  border-color: transparent;
  border-radius: 8px;
  height: 348px;
  background-size: cover;
  background-position: center;
  @media (max-width: ($iphone-devices-max-width - 0.02)+px) {
    height: 180px;
  }
  @media (min-width: $iphone-devices-max-width+px) and (max-width: $mobile-devices-max-width+px) {
    height: 200px;
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    background-size: 100px;
  }
}
.responsibility-card {
  // height: 311px;
  border: 1px solid $theme-border-color;
  // padding: 24px;
  overflow: hidden;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: right center;
  @media (max-width: ($iphone-devices-max-width - 0.02)+px) {
    background-size: 100px;
    height: 180px;
    background-position: right bottom;
  }
  @media (min-width: $iphone-devices-max-width+px) and (max-width: $mobile-devices-max-width+px) {
    background-size: 150px;
    // height: 200px;
    background-position: right bottom;
  }
  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    background-size: 100px;
    // height: 200px;
  }
  p {
    color: $theme-font-primary-color;
    padding-bottom: 16px;
  }
  button {
    padding: 10px 16px;
    color: #fff;
    background-color: #1539b0;
  }
}
